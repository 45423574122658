import React, {useState, useEffect} from 'react';

import parse from "html-react-parser";
import '../assets/styles/figure.css';
// We're using Gutenberg so we need the block styles
// import "@wordpress/block-library/build-style/style.css";
// import "@wordpress/block-library/build-style/theme.css";

import {Link as LinkGatsby} from "gatsby"
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import {graphql, useStaticQuery} from 'gatsby';
import Instagram from '../assets/images/icons-insta-white.svg';
import Facebook from '../assets/images/facebook-logo-white.svg';
import linkedin from '../assets/images/icons-linkedin-white.svg';
import Image from "gatsby-image";
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import Flickity from 'react-flickity-component';
import Button from "@material-ui/core/Button";
import { navigate } from "gatsby";
import Social from '../components/Social';

import ReactHtmlParser, {processNodes, convertNodeToElement, htmlparser2} from 'react-html-parser';
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import "../assets/styles/flickity.css";

const flickityOptions = {
    cellAlign: 'center',
    wrapAround: false,
    groupCells: 4,
    pageDots: false,
    imagesLoaded: false,
    contain: true,
    autoPlay: 3500,
    arrowShape: 'M51.7,97.7l4.4-4.4c1-1,1-2.7,0-3.7L21.9,55.5H97c1.5,0,2.6-1.2,2.6-2.6v-6.2c0-1.5-1.2-2.6-2.6-2.6H21.9 L56,9.9c1-1,1-2.7,0-3.7l-4.4-4.4c-1-1-2.7-1-3.7,0L1.9,47.9c-1,1-1,2.7,0,3.7l46.1,46.1C49,98.7,50.7,98.7,51.7,97.7z'
}

const NewspaperPostTemplate = ({ location, data:
    {
        previous,
        next,
        post,
        wooGallery,
        newImage,
        adFeatured,
        customWidget1,
        customWidget2,
        customWidget3,
        customWidget4,
        customWidget5,
        customWidget6,
        customWidget7,
        customWidget8,
        customWidget9
    }}) => {

    const getGallery = [];

    const {t} = useTranslation();
    const {language, changeLanguage} = useI18next();
    const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        //  Enable Flickity
        setTimeout(function () {
            setPageLoaded(true);
        }, 0);
    }, []);

    let [flkty, setFlkty] = useState(null);

    useEffect(() => {
        if (flkty) {
            console.log("this ran");
            flkty.on("settle", () => {
                console.log(`current index is ${flkty.selectedIndex}`);
            });
        }
    },[]);

    const featuredImage = {
        fluid: newImage?.reading?.articleMainImage?.localFile?.childImageSharp?.fluid,
        alt: newImage?.altText || ``,
    };

    //  Main Slot
    let featuredSlotPost;

    let widget1;

    let widget2;

    let widget3;

    let widget4;

    let widget5;

    let widget6;

    let widget7;

    let widget8;

    let widget9;

    if ( customWidget1?.customPostWidget1 !== null ) {
        widget1 = {
            urlLargeSection: customWidget1?.customPostWidget1?.urlLargeSectionWidget1,
            urlSmallSection: customWidget1?.customPostWidget1?.urlSmallSectionWidget1,
            showBorderLarge: (customWidget1?.customPostWidget1?.showBorderLargeSectionWidget1) ? true : false,
            showBorderSmall: (customWidget1?.customPostWidget1?.showBorderSmallSectionWidget1) ? true : false,
            imageLarge: {
                sourceUrl: customWidget1?.customPostWidget1?.largeImageSectionWidget1?.localFile?.publicURL,
                alt:customWidget1?.customPostWidget1?.largeImageSectionWidget1?.localFile?.altText || ``,
            },
            imageSmall: {
                sourceUrl: customWidget1?.customPostWidget1?.imageSmallSectionWidget1?.localFile?.publicURL,
                alt: customWidget1?.customPostWidget1?.imageSmallSectionWidget1?.localFile?.altText || ``,
            },
            buttonTextLarge: (language !== 'en') ? customWidget1?.customPostWidget1?.buttonTextLargeSectionWidget1 : customWidget1?.['translations'][0]?.buttonTextLargeSectionWidget1,
            buttonTextSmall: (language !== 'en') ? customWidget1?.customPostWidget1?.buttonTextSmallSectionWidget1 : customWidget1?.['translations'][0]?.buttonTextSmallSectionWidget1,
            titleTextLarge: (language !== 'en') ? customWidget1?.customPostWidget1?.titleWidget1  : customWidget1?.['translations'][0]?.titleWidget1,
            titleTextSmall: (language !== 'en') ? customWidget1?.customPostWidget1?.titleSmallSectionWidget1  : customWidget1?.['translations'][0]?.titleSmallSectionWidget1,
            descriptionTextLarge: (language !== 'en') ? customWidget1?.customPostWidget1?.descriptionWidget1  : customWidget1?.['translations'][0]?.descriptionWidget1,
            descriptionTextSmall: (language !== 'en') ? customWidget1?.customPostWidget1?.descriptionSmallSectionWidget1  : customWidget1?.['translations'][0]?.descriptionSmallSectionWidget1,
        };
    }

    if ( customWidget2?.customPostWidget2 !== null ) {
        widget2 = {
            urlLargeSection: customWidget2?.customPostWidget2?.urlLargeSectionWidget2,
            urlSmallSection: customWidget2?.customPostWidget2?.urlSmallSectionWidget2,
            showBorderLarge: (customWidget2?.customPostWidget2?.showBorderLargeSectionWidget2) ? true : false,
            showBorderSmall: (customWidget2?.customPostWidget2?.showBorderSmallSectionWidget2) ? true : false,
            imageLarge: {
                sourceUrl: customWidget2?.customPostWidget2?.largeImageSectionWidget2?.localFile?.publicURL,
                alt:customWidget2?.customPostWidget2?.largeImageSectionWidget2?.localFile?.altText || ``,
            },
            imageSmall: {
                sourceUrl: customWidget2?.customPostWidget2?.imageSmallSectionWidget2?.localFile?.publicURL,
                alt: customWidget2?.customPostWidget2?.imageSmallSectionWidget2?.localFile?.altText || ``,
            },
            buttonTextLarge: (language !== 'en') ? customWidget2?.customPostWidget2?.buttonTextLargeSectionWidget2 : customWidget2?.['translations'][0]?.buttonTextLargeSectionWidget2,
            buttonTextSmall: (language !== 'en') ? customWidget2?.customPostWidget2?.buttonTextSmallSectionWidget2 : customWidget2?.['translations'][0]?.buttonTextSmallSectionWidget2,
            titleTextLarge: (language !== 'en') ? customWidget2?.customPostWidget2?.titleWidget2  : customWidget2?.['translations'][0]?.titleWidget2,
            titleTextSmall: (language !== 'en') ? customWidget2?.customPostWidget2?.titleSmallSectionWidget2  : customWidget2?.['translations'][0]?.titleSmallSectionWidget2,
            descriptionTextLarge: (language !== 'en') ? customWidget2?.customPostWidget2?.descriptionWidget2  : customWidget2?.['translations'][0]?.descriptionWidget2,
            descriptionTextSmall: (language !== 'en') ? customWidget2?.customPostWidget2?.descriptionSmallSectionWidget2  : customWidget2?.['translations'][0]?.descriptionSmallSectionWidget2,
        };
    }

    if ( customWidget3?.customPostWidget3 !== null ) {
        widget3 = {
            urlLargeSection: customWidget3?.customPostWidget3?.urlLargeSectionWidget3,
            urlSmallSection: customWidget3?.customPostWidget3?.urlSmallSectionWidget3,
            showBorderLarge: (customWidget3?.customPostWidget3?.showBorderLargeSectionWidget3) ? true : false,
            showBorderSmall: (customWidget3?.customPostWidget3?.showBorderSmallSectionWidget3) ? true : false,
            imageLarge: {
                sourceUrl: customWidget3?.customPostWidget3?.largeImageSectionWidget3?.localFile?.publicURL,
                alt:customWidget3?.customPostWidget3?.largeImageSectionWidget3?.localFile?.altText || ``,
            },
            imageSmall: {
                sourceUrl: customWidget3?.customPostWidget3?.imageSmallSectionWidget3?.localFile?.publicURL,
                alt: customWidget3?.customPostWidget3?.imageSmallSectionWidget3?.localFile?.altText || ``,
            },
            buttonTextLarge: (language !== 'en') ? customWidget3?.customPostWidget3?.buttonTextLargeSectionWidget3 : customWidget3?.['translations'][0]?.buttonTextLargeSectionWidget3,
            buttonTextSmall: (language !== 'en') ? customWidget3?.customPostWidget3?.buttonTextSmallSectionWidget3 : customWidget3?.['translations'][0]?.buttonTextSmallSectionWidget3,
            titleTextLarge: (language !== 'en') ? customWidget3?.customPostWidget3?.titleWidget3  : customWidget3?.['translations'][0]?.titleWidget3,
            titleTextSmall: (language !== 'en') ? customWidget3?.customPostWidget3?.titleSmallSectionWidget3  : customWidget3?.['translations'][0]?.titleSmallSectionWidget3,
            descriptionTextLarge: (language !== 'en') ? customWidget3?.customPostWidget3?.descriptionWidget3  : customWidget3?.['translations'][0]?.descriptionWidget3,
            descriptionTextSmall: (language !== 'en') ? customWidget3?.customPostWidget3?.descriptionSmallSectionWidget3  : customWidget3?.['translations'][0]?.descriptionSmallSectionWidget3,
        };
    }

    if ( customWidget4?.customPostWidget4 !== null ) {
        widget4 = {
            urlLargeSection: customWidget4?.customPostWidget4?.urlLargeSectionWidget4,
            urlSmallSection: customWidget4?.customPostWidget4?.urlSmallSectionWidget4,
            showBorderLarge: (customWidget4?.customPostWidget4?.showBorderLargeSectionWidget4) ? true : false,
            showBorderSmall: (customWidget4?.customPostWidget4?.showBorderSmallSectionWidget4) ? true : false,
            imageLarge: {
                sourceUrl: customWidget4?.customPostWidget4?.largeImageSectionWidget4?.localFile?.publicURL,
                alt:customWidget4?.customPostWidget4?.largeImageSectionWidget4?.localFile?.altText || ``,
            },
            imageSmall: {
                sourceUrl: customWidget4?.customPostWidget4?.imageSmallSectionWidget4?.localFile?.publicURL,
                alt: customWidget4?.customPostWidget4?.imageSmallSectionWidget4?.localFile?.altText || ``,
            },
            buttonTextLarge: (language !== 'en') ? customWidget4?.customPostWidget4?.buttonTextLargeSectionWidget4 : customWidget4?.['translations'][0]?.buttonTextLargeSectionWidget4,
            buttonTextSmall: (language !== 'en') ? customWidget4?.customPostWidget4?.buttonTextSmallSectionWidget4 : customWidget4?.['translations'][0]?.buttonTextSmallSectionWidget4,
            titleTextLarge: (language !== 'en') ? customWidget4?.customPostWidget4?.titleWidget4  : customWidget4?.['translations'][0]?.titleWidget4,
            titleTextSmall: (language !== 'en') ? customWidget4?.customPostWidget4?.titleSmallSectionWidget4  : customWidget4?.['translations'][0]?.titleSmallSectionWidget4,
            descriptionTextLarge: (language !== 'en') ? customWidget4?.customPostWidget4?.descriptionWidget4  : customWidget4?.['translations'][0]?.descriptionWidget4,
            descriptionTextSmall: (language !== 'en') ? customWidget4?.customPostWidget4?.descriptionSmallSectionWidget4  : customWidget4?.['translations'][0]?.descriptionSmallSectionWidget4,
        };
    }

    if ( customWidget5?.customPostWidget5 !== null ) {
        widget5 = {
            urlLargeSection: customWidget5?.customPostWidget5?.urlLargeSectionWidget5,
            urlSmallSection: customWidget5?.customPostWidget5?.urlSmallSectionWidget5,
            showBorderLarge: (customWidget5?.customPostWidget5?.showBorderLargeSectionWidget5) ? true : false,
            showBorderSmall: (customWidget5?.customPostWidget5?.showBorderSmallSectionWidget5) ? true : false,
            imageLarge: {
                sourceUrl: customWidget5?.customPostWidget5?.largeImageSectionWidget5?.localFile?.publicURL,
                alt:customWidget5?.customPostWidget5?.largeImageSectionWidget5?.localFile?.altText || ``,
            },
            imageSmall: {
                sourceUrl: customWidget5?.customPostWidget5?.imageSmallSectionWidget5?.localFile?.publicURL,
                alt: customWidget5?.customPostWidget5?.imageSmallSectionWidget5?.localFile?.altText || ``,
            },
            buttonTextLarge: (language !== 'en') ? customWidget5?.customPostWidget5?.buttonTextLargeSectionWidget5 : customWidget5?.['translations'][0]?.buttonTextLargeSectionWidget5,
            buttonTextSmall: (language !== 'en') ? customWidget5?.customPostWidget5?.buttonTextSmallSectionWidget5 : customWidget5?.['translations'][0]?.buttonTextSmallSectionWidget5,
            titleTextLarge: (language !== 'en') ? customWidget5?.customPostWidget5?.titleWidget5  : customWidget5?.['translations'][0]?.titleWidget5,
            titleTextSmall: (language !== 'en') ? customWidget5?.customPostWidget5?.titleSmallSectionWidget5  : customWidget5?.['translations'][0]?.titleSmallSectionWidget5,
            descriptionTextLarge: (language !== 'en') ? customWidget5?.customPostWidget5?.descriptionWidget5  : customWidget5?.['translations'][0]?.descriptionWidget5,
            descriptionTextSmall: (language !== 'en') ? customWidget5?.customPostWidget5?.descriptionSmallSectionWidget5  : customWidget5?.['translations'][0]?.descriptionSmallSectionWidget5,
        };
    }

    if ( customWidget6?.customPostWidget6 !== null ) {
        widget6 = {
            urlLargeSection: customWidget6?.customPostWidget6?.urlLargeSectionWidget6,
            urlSmallSection: customWidget6?.customPostWidget6?.urlSmallSectionWidget6,
            showBorderLarge: (customWidget6?.customPostWidget6?.showBorderLargeSectionWidget6) ? true : false,
            showBorderSmall: (customWidget6?.customPostWidget6?.showBorderSmallSectionWidget6) ? true : false,
            imageLarge: {
                sourceUrl: customWidget6?.customPostWidget6?.largeImageSectionWidget6?.localFile?.publicURL,
                alt:customWidget6?.customPostWidget6?.largeImageSectionWidget6?.localFile?.altText || ``,
            },
            imageSmall: {
                sourceUrl: customWidget6?.customPostWidget6?.imageSmallSectionWidget6?.localFile?.publicURL,
                alt: customWidget6?.customPostWidget6?.imageSmallSectionWidget6?.localFile?.altText || ``,
            },
            buttonTextLarge: (language !== 'en') ? customWidget6?.customPostWidget6?.buttonTextLargeSectionWidget6 : customWidget6?.['translations'][0]?.buttonTextLargeSectionWidget6,
            buttonTextSmall: (language !== 'en') ? customWidget6?.customPostWidget6?.buttonTextSmallSectionWidget6 : customWidget6?.['translations'][0]?.buttonTextSmallSectionWidget6,
            titleTextLarge: (language !== 'en') ? customWidget6?.customPostWidget6?.titleWidget6  : customWidget6?.['translations'][0]?.titleWidget6,
            titleTextSmall: (language !== 'en') ? customWidget6?.customPostWidget6?.titleSmallSectionWidget6  : customWidget6?.['translations'][0]?.titleSmallSectionWidget6,
            descriptionTextLarge: (language !== 'en') ? customWidget6?.customPostWidget6?.descriptionWidget6  : customWidget6?.['translations'][0]?.descriptionWidget6,
            descriptionTextSmall: (language !== 'en') ? customWidget6?.customPostWidget6?.descriptionSmallSectionWidget6  : customWidget6?.['translations'][0]?.descriptionSmallSectionWidget6,
        };
    }

    if ( customWidget7?.customPostWidget7 !== null ) {
        widget7 = {
            urlLargeSection: customWidget7?.customPostWidget7?.urlLargeSectionWidget7,
            urlSmallSection: customWidget7?.customPostWidget7?.urlSmallSectionWidget7,
            showBorderLarge: (customWidget7?.customPostWidget7?.showBorderLargeSectionWidget7) ? true : false,
            showBorderSmall: (customWidget7?.customPostWidget7?.showBorderSmallSectionWidget7) ? true : false,
            imageLarge: {
                sourceUrl: customWidget7?.customPostWidget7?.largeImageSectionWidget7?.localFile?.publicURL,
                alt:customWidget7?.customPostWidget7?.largeImageSectionWidget7?.localFile?.altText || ``,
            },
            imageSmall: {
                sourceUrl: customWidget7?.customPostWidget7?.imageSmallSectionWidget7?.localFile?.publicURL,
                alt: customWidget7?.customPostWidget7?.imageSmallSectionWidget7?.localFile?.altText || ``,
            },
            buttonTextLarge: (language !== 'en') ? customWidget7?.customPostWidget7?.buttonTextLargeSectionWidget7 : customWidget7?.['translations'][0]?.buttonTextLargeSectionWidget7,
            buttonTextSmall: (language !== 'en') ? customWidget7?.customPostWidget7?.buttonTextSmallSectionWidget7 : customWidget7?.['translations'][0]?.buttonTextSmallSectionWidget7,
            titleTextLarge: (language !== 'en') ? customWidget7?.customPostWidget7?.titleWidget7  : customWidget7?.['translations'][0]?.titleWidget7,
            titleTextSmall: (language !== 'en') ? customWidget7?.customPostWidget7?.titleSmallSectionWidget7  : customWidget7?.['translations'][0]?.titleSmallSectionWidget7,
            descriptionTextLarge: (language !== 'en') ? customWidget7?.customPostWidget7?.descriptionWidget7  : customWidget7?.['translations'][0]?.descriptionWidget7,
            descriptionTextSmall: (language !== 'en') ? customWidget7?.customPostWidget7?.descriptionSmallSectionWidget7  : customWidget7?.['translations'][0]?.descriptionSmallSectionWidget7,
        };
    }

    if ( customWidget8?.customPostWidget8 !== null ) {
        widget8 = {
            urlLargeSection: customWidget8?.customPostWidget8?.urlLargeSectionWidget8,
            urlSmallSection: customWidget8?.customPostWidget8?.urlSmallSectionWidget8,
            showBorderLarge: (customWidget8?.customPostWidget8?.showBorderLargeSectionWidget8) ? true : false,
            showBorderSmall: (customWidget8?.customPostWidget8?.showBorderSmallSectionWidget8) ? true : false,
            imageLarge: {
                sourceUrl: customWidget8?.customPostWidget8?.largeImageSectionWidget8?.localFile?.publicURL,
                alt:customWidget8?.customPostWidget8?.largeImageSectionWidget8?.localFile?.altText || ``,
            },
            imageSmall: {
                sourceUrl: customWidget8?.customPostWidget8?.imageSmallSectionWidget8?.localFile?.publicURL,
                alt: customWidget8?.customPostWidget8?.imageSmallSectionWidget8?.localFile?.altText || ``,
            },
            buttonTextLarge: (language !== 'en') ? customWidget8?.customPostWidget8?.buttonTextLargeSectionWidget8 : customWidget8?.['translations'][0]?.buttonTextLargeSectionWidget8,
            buttonTextSmall: (language !== 'en') ? customWidget8?.customPostWidget8?.buttonTextSmallSectionWidget8 : customWidget8?.['translations'][0]?.buttonTextSmallSectionWidget8,
            titleTextLarge: (language !== 'en') ? customWidget8?.customPostWidget8?.titleWidget7  : customWidget8?.['translations'][0]?.titleWidget8,
            titleTextSmall: (language !== 'en') ? customWidget8?.customPostWidget8?.titleSmallSectionWidget8  : customWidget8?.['translations'][0]?.titleSmallSectionWidget8,
            descriptionTextLarge: (language !== 'en') ? customWidget8?.customPostWidget8?.descriptionWidget8  : customWidget8?.['translations'][0]?.descriptionWidget8,
            descriptionTextSmall: (language !== 'en') ? customWidget8?.customPostWidget8?.descriptionSmallSectionWidget8  : customWidget8?.['translations'][0]?.descriptionSmallSectionWidget8,
        };
    }

    if ( customWidget9?.customPostWidget9 !== null ) {
        widget9 = {
            urlLargeSection: customWidget9?.customPostWidget9?.urlLargeSectionWidget9,
            urlSmallSection: customWidget9?.customPostWidget9?.urlSmallSectionWidget9,
            showBorderLarge: (customWidget9?.customPostWidget9?.showBorderLargeSectionWidget9) ? true : false,
            showBorderSmall: (customWidget9?.customPostWidget9?.showBorderSmallSectionWidget9) ? true : false,
            imageLarge: {
                sourceUrl: customWidget9?.customPostWidget9?.largeImageSectionWidget9?.localFile?.publicURL,
                alt:customWidget9?.customPostWidget9?.largeImageSectionWidget9?.localFile?.altText || ``,
            },
            imageSmall: {
                sourceUrl: customWidget9?.customPostWidget9?.imageSmallSectionWidget9?.localFile?.publicURL,
                alt: customWidget9?.customPostWidget9?.imageSmallSectionWidget9?.localFile?.altText || ``,
            },
            buttonTextLarge: (language !== 'en') ? customWidget9?.customPostWidget9?.buttonTextLargeSectionWidget9 : customWidget9?.['translations'][0]?.buttonTextLargeSectionWidget9,
            buttonTextSmall: (language !== 'en') ? customWidget9?.customPostWidget9?.buttonTextSmallSectionWidget9 : customWidget9?.['translations'][0]?.buttonTextSmallSectionWidget9,
            titleTextLarge: (language !== 'en') ? customWidget9?.customPostWidget9?.titleWidget9  : customWidget9?.['translations'][0]?.titleWidget9,
            titleTextSmall: (language !== 'en') ? customWidget9?.customPostWidget9?.titleSmallSectionWidget9  : customWidget9?.['translations'][0]?.titleSmallSectionWidget9,
            descriptionTextLarge: (language !== 'en') ? customWidget9?.customPostWidget9?.descriptionWidget9  : customWidget9?.['translations'][0]?.descriptionWidget9,
            descriptionTextSmall: (language !== 'en') ? customWidget9?.customPostWidget9?.descriptionSmallSectionWidget9  : customWidget9?.['translations'][0]?.descriptionSmallSectionWidget9,
        };
    }

    //  Widget Slot 1
    let AdPost = () => {

        if(widget1?.imageLarge?.sourceUrl && widget1?.imageSmall?.sourceUrl) {
            return(

                <div className="in-article-block">

                    <div className="block-side">
                        <LinkGatsby target={'_blank'}
                                    to={widget1?.urlSmallSection}
                                    className={(widget1.showBorderSmall || isMobile ) ? 'hover-link fixed-link' : 'hover-link fixed-link'}>
                            <div className="thumbwrap">
                                <img
                                    src={widget1?.imageSmall?.sourceUrl}
                                    alt={widget1?.imageSmall?.alt}
                                    className="img-fluid full"
                                />
                                <span className="more events">{widget1?.buttonTextSmall}</span>
                            </div>

                        </LinkGatsby>
                    </div>

                    <div className="img-side">
                        <LinkGatsby target={'_self'}
                                    to={widget1?.urlLargeSection}
                                    className={(widget1.showBorderLarge || isMobile ) ? 'hover-link fixed-link' : 'hover-link right-link'}>
                            <div className="thumbwrap">
                                <img
                                    src={widget1?.imageLarge?.sourceUrl}
                                    alt={widget1?.imageLarge?.alt}
                                    className="img-fluid full"
                                />
                                <span className="more events">{widget1?.buttonTextLarge}</span>
                            </div>
                            <h3>{widget1?.titleTextLarge}</h3>
                            <p className='slotText'>{widget1?.descriptionTextLarge}</p>
                        </LinkGatsby>
                    </div>

                </div>
            );
        }


    };

    //  Widget Slot 2
    let AdPost2 = () => {

        if(widget2?.imageLarge?.sourceUrl && widget2?.imageSmall?.sourceUrl) {
            return(

                <div className="in-article-block in-article-block-2">

                    <div className="img-side">
                        <LinkGatsby target={'_self'}
                                    to={widget2?.urlLargeSection}
                                    className={(widget2.showBorderLarge || isMobile) ? 'hover-link fixed-link' : 'hover-link right-link'}>
                            <div className="thumbwrap">
                                <img
                                    src={widget2?.imageLarge?.sourceUrl}
                                    alt={widget2?.imageLarge?.alt}
                                    className="img-fluid full"
                                />
                                <span className="more events">{widget2?.buttonTextLarge}</span>
                            </div>
                            <h3>{widget2?.titleTextLarge}</h3>
                            <p className='slotText'>{widget2?.descriptionTextLarge}</p>
                        </LinkGatsby>
                    </div>

                    <div className="block-side">
                        <LinkGatsby target={'_blank'}
                                    to={widget2?.urlSmallSection}
                                    className={(widget2.showBorderSmall || isMobile) ? 'hover-link fixed-link' : 'hover-link fixed-link'}>
                            <div className="thumbwrap">
                                <img
                                    src={widget2?.imageSmall?.sourceUrl}
                                    alt={widget2?.imageSmall?.alt}
                                    className="img-fluid full"
                                />
                                <span className="more events">{widget2?.buttonTextSmall}</span>
                            </div>

                        </LinkGatsby>
                    </div>

                </div>
            );
        }

    };

    //  Widget Slot 3
    let AdPost3 = () => {

        if(widget3?.imageLarge?.sourceUrl && widget3?.imageSmall?.sourceUrl) {
            return(

                <div className="in-article-block">

                    <div className="block-side">
                        <LinkGatsby target={'_blank'}
                                    to={widget3?.urlSmallSection}
                                    className={(widget3.showBorderSmall || isMobile) ? 'hover-link fixed-link' : 'hover-link  fixed-link'}>
                            <div className="thumbwrap">
                                <img
                                    src={widget3?.imageSmall?.sourceUrl}
                                    alt={widget3?.imageSmall?.alt}
                                    className="img-fluid full"
                                />
                                <span className="more events">{widget3?.buttonTextSmall}</span>
                            </div>

                        </LinkGatsby>
                    </div>

                    <div className="img-side">
                        <LinkGatsby target={'_self'}
                                    to={widget3?.urlLargeSection}
                                    className={(widget3.showBorderLarge || isMobile) ? 'hover-link fixed-link' : 'hover-link right-link'}>
                            <div className="thumbwrap">
                                <img
                                    src={widget3?.imageLarge?.sourceUrl}
                                    alt={widget3?.imageLarge?.alt}
                                    className="img-fluid full"
                                />
                                <span className="more events">{widget3?.buttonTextLarge}</span>
                            </div>
                            <h3>{widget3?.titleTextLarge}</h3>
                            <p className='slotText'>{widget3?.descriptionTextLarge}</p>
                        </LinkGatsby>
                    </div>

                </div>
            );
        }

    };

    //  Widget Slot 4
    let AdPost4 = () => {

        if(widget4?.imageLarge?.sourceUrl && widget4?.imageSmall?.sourceUrl) {
            return(

                <div className="in-article-block in-article-block-2">

                    <div className="img-side">
                        <LinkGatsby target={'_self'}
                                    to={widget4?.urlLargeSection}
                                    className={(widget4.showBorderLarge || isMobile) ? 'hover-link fixed-link' : 'hover-link right-link '}>
                            <div className="thumbwrap">
                                <img
                                    src={widget4?.imageLarge?.sourceUrl}
                                    alt={widget4?.imageLarge?.alt}
                                    className="img-fluid full"
                                />
                                <span className="more events">{widget4?.buttonTextLarge}</span>
                            </div>
                            <h3>{widget4?.titleTextLarge}</h3>
                            <p className='slotText'>{widget4?.descriptionTextLarge}</p>
                        </LinkGatsby>
                    </div>

                    <div className="block-side">
                        <LinkGatsby target={'_blank'}
                                    to={widget4?.urlSmallSection}
                                    className={(widget4.showBorderSmall || isMobile) ? 'hover-link fixed-link' : 'hover-link fixed-link '}>
                            <div className="thumbwrap">
                                <img
                                    src={widget4?.imageSmall?.sourceUrl}
                                    alt={widget4?.imageSmall?.alt}
                                    className="img-fluid full"
                                />
                                <span className="more events">{widget4?.buttonTextSmall}</span>
                            </div>

                        </LinkGatsby>
                    </div>

                </div>
            );
        }

    };

    //  Widget Slot 5
    let AdPost5 = () => {

        if(widget5?.imageLarge?.sourceUrl && widget5?.imageSmall?.sourceUrl) {
            return(

                <div className="in-article-block">

                    <div className="block-side">
                        <LinkGatsby target={'_blank'}
                                    to={widget5?.urlSmallSection}
                                    className={(widget5.showBorderSmall || isMobile) ? 'hover-link fixed-link' : 'hover-link  fixed-link'}>
                            <div className="thumbwrap">
                                <img
                                    src={widget5?.imageSmall?.sourceUrl}
                                    alt={widget5?.imageSmall?.alt}
                                    className="img-fluid full"
                                />
                                <span className="more events">{widget5?.buttonTextSmall}</span>
                            </div>

                        </LinkGatsby>
                    </div>

                    <div className="img-side">
                        <LinkGatsby target={'_self'}
                                    to={widget5?.urlLargeSection}
                                    className={(widget5.showBorderLarge || isMobile) ? 'hover-link fixed-link' : 'hover-link right-link '}>
                            <div className="thumbwrap">
                                <img
                                    src={widget5?.imageLarge?.sourceUrl}
                                    alt={widget5?.imageLarge?.alt}
                                    className="img-fluid full"
                                />
                                <span className="more events">{widget5?.buttonTextLarge}</span>
                            </div>
                            <h3>{widget5?.titleTextLarge}</h3>
                            <p className='slotText'>{widget5?.descriptionTextLarge}</p>
                        </LinkGatsby>
                    </div>

                </div>
            );
        }


    };

    //  Widget Slot 6
    let AdPost6 = () => {

        if(widget6?.imageLarge?.sourceUrl && widget6?.imageSmall?.sourceUrl) {
            return(

                <div className="in-article-block in-article-block-2">

                    <div className="img-side">
                        <LinkGatsby target={'_self'}
                                    to={widget6?.urlLargeSection}
                                    className={(widget6.showBorderLarge || isMobile) ? 'hover-link fixed-link' : 'hover-link  right-link'}>
                            <div className="thumbwrap">
                                <img
                                    src={widget6?.imageLarge?.sourceUrl}
                                    alt={widget6?.imageLarge?.alt}
                                    className="img-fluid full"
                                />
                                <span className="more events">{widget6?.buttonTextLarge}</span>
                            </div>
                            <h3>{widget6?.titleTextLarge}</h3>
                            <p className='slotText'>{widget6?.descriptionTextLarge}</p>
                        </LinkGatsby>
                    </div>

                    <div className="block-side">
                        <LinkGatsby target={'_blank'}
                                    to={widget6?.urlSmallSection}
                                    className={(widget6.showBorderSmall || isMobile) ? 'hover-link fixed-link' : 'hover-link fixed-link '}>
                            <div className="thumbwrap">
                                <img
                                    src={widget6?.imageSmall?.sourceUrl}
                                    alt={widget6?.imageSmall?.alt}
                                    className="img-fluid full"
                                />
                                <span className="more events">{widget6?.buttonTextSmall}</span>
                            </div>

                        </LinkGatsby>
                    </div>

                </div>
            );
        }


    };

    //  Widget Slot 7
    let AdPost7 = () => {

        if(widget7?.imageLarge?.sourceUrl && widget7?.imageSmall?.sourceUrl) {
            return(

                <div className="in-article-block">

                    <div className="block-side">
                        <LinkGatsby target={'_blank'}
                                    to={widget7?.urlSmallSection}
                                    className={(widget7.showBorderSmall || isMobile) ? 'hover-link fixed-link' : 'hover-link fixed-link '}>
                            <div className="thumbwrap">
                                <img
                                    src={widget7?.imageSmall?.sourceUrl}
                                    alt={widget7?.imageSmall?.alt}
                                    className="img-fluid full"
                                />
                                <span className="more events">{widget7?.buttonTextSmall}</span>
                            </div>

                        </LinkGatsby>
                    </div>

                    <div className="img-side">
                        <LinkGatsby target={'_self'}
                                    to={widget7?.urlLargeSection}
                                    className={(widget7.showBorderLarge || isMobile) ? 'hover-link fixed-link' : 'hover-link right-link '}>
                            <div className="thumbwrap">
                                <img
                                    src={widget7?.imageLarge?.sourceUrl}
                                    alt={widget7?.imageLarge?.alt}
                                    className="img-fluid full"
                                />
                                <span className="more events">{widget7?.buttonTextLarge}</span>
                            </div>
                            <h3>{widget7?.titleTextLarge}</h3>
                            <p className='slotText'>{widget7?.descriptionTextLarge}</p>
                        </LinkGatsby>
                    </div>

                </div>
            );
        }

    };

    //  Widget Slot 8
    let AdPost8 = () => {

        if(widget8?.imageLarge?.sourceUrl && widget8?.imageSmall?.sourceUrl) {

            return(

                <div className="in-article-block in-article-block-2">

                    <div className="img-side">
                        <LinkGatsby target={'_self'}
                                    to={widget8?.urlLargeSection}
                                    className={(widget8.showBorderLarge || isMobile) ? 'hover-link fixed-link' : 'hover-link fixed-link'}>
                            <div className="thumbwrap">
                                <img
                                    src={widget8?.imageLarge?.sourceUrl}
                                    alt={widget8?.imageLarge?.alt}
                                    className="img-fluid full"
                                />
                                <span className="more events">{widget8?.buttonTextLarge}</span>
                            </div>
                            <h3>{widget8?.titleTextLarge}</h3>
                            <p className='slotText'>{widget8?.descriptionTextLarge}</p>
                        </LinkGatsby>
                    </div>

                    <div className="block-side">
                        <LinkGatsby target={'_blank'}
                                    to={widget8?.urlSmallSection}
                                    className={(widget8.showBorderSmall || isMobile) ? 'hover-link fixed-link' : 'hover-link right-link '}>
                            <div className="thumbwrap">
                                <img
                                    src={widget8?.imageSmall?.sourceUrl}
                                    alt={widget8?.imageSmall?.alt}
                                    className="img-fluid full"
                                />
                                <span className="more events">{widget8?.buttonTextSmall}</span>
                            </div>

                        </LinkGatsby>
                    </div>

                </div>
            );

        }

    };

    //  Widget Slot 7
    let AdPost9 = () => {

            if(widget9?.imageLarge?.sourceUrl && widget9?.imageSmall?.sourceUrl){

                return(

                    <div className="in-article-block">

                        <div className="block-side">
                            <LinkGatsby target={'_blank'}
                                        to={widget9?.urlSmallSection}
                                        className={(widget9.showBorderSmall || isMobile) ? 'hover-link fixed-link' : 'hover-link right-link '}>
                                <div className="thumbwrap">
                                    <img
                                        src={widget9?.imageSmall?.sourceUrl}
                                        alt={widget9?.imageSmall?.alt}
                                        className="img-fluid full"
                                    />
                                    <span className="more events">{widget9?.buttonTextSmall}</span>
                                </div>

                            </LinkGatsby>
                        </div>

                        <div className="img-side">
                            <LinkGatsby target={'_self'}
                                        to={widget9?.urlLargeSection}
                                        className={(widget9.showBorderLarge || isMobile) ? 'hover-link fixed-link' : 'hover-link fixed-link '}>
                                <div className="thumbwrap">
                                    <img
                                        src={widget9?.imageLarge?.sourceUrl}
                                        alt={widget9?.imageLarge?.alt}
                                        className="img-fluid full"
                                    />
                                    <span className="more events">{widget9?.buttonTextLarge}</span>
                                </div>
                                <h3>{widget9?.titleTextLarge}</h3>
                                <p className='slotText'>{widget9?.descriptionTextLarge}</p>
                            </LinkGatsby>
                        </div>

                    </div>
                );

            }

    };


    function transform(node, index) {
        // // return null to block certain elements
        // // don't allow <span> elements
        // if (node.type === "tag" && node.name === "span") {
        //     return null;
        // }
        //
        // // Transform <ul> into <ol>
        // // A node can be modified and passed to the convertNodeToElement function which will continue to render it and it's children
        // if (node.type === "tag" && node.name === "ul") {
        //     node.name = "ol";
        //     return convertNodeToElement(node, index, transform);
        // }
        //
        // // return an <i> element for every <b>
        // // a key must be included for all elements
        // if (node.type === "tag" && node.name === "b") {
        //     return <i key={index}>{processNodes(node.children, transform)}</i>;
        // }
        //
        // // all links must open in a new window
        // if (node.type === "tag" && node.name === "a") {
        //     node.attribs.target = "_blank";
        //     // console.log(node);
        //     // console.log(index);
        //     return convertNodeToElement(node, index, transform);
        // }
        //
        // if (node.type === "tag" && node.name === "button") {
        //     return (
        //         <Button variant="contained" color="primary" key={index}>
        //             {processNodes(node.children, transform)}
        //         </Button>
        //     );
        // }

        if (node.type === "tag" && node.name === "customwidget1") {
            return (
                <AdPost/>
            );
        }

        if (node.type === "tag" && node.name === "customwidget2") {
            return (
                <AdPost2/>
            );
        }

        if (node.type === "tag" && node.name === "customwidget3") {
            return (
                <AdPost3/>
            );
        }

        if (node.type === "tag" && node.name === "customwidget4") {
            return (
                <AdPost4/>
            );
        }

        if (node.type === "tag" && node.name === "customwidget5") {
            return (
                <AdPost5/>
            );
        }

        if (node.type === "tag" && node.name === "customwidget6") {
            return (
                <AdPost6/>
            );
        }

        if (node.type === "tag" && node.name === "customwidget7") {
            return (
                <AdPost7/>
            );
        }

        if (node.type === "tag" && node.name === "customwidget8") {
            return (
                <AdPost8/>
            );
        }

        if (node.type === "tag" && node.name === "customwidget9") {
            return (
                <AdPost9/>
            );
        }

    }

    const options = {
        decodeEntities: true,
        transform
    };

    let pathArray = location.pathname.split('/').filter(n => n);

    const myCustomPrev = () =>{
        flkty.previous();
    }


    const myCustomNext = () =>{
        flkty.next();
    }

    return (
        <Layout>
            <SEO title={post?.title} description={post?.excerpt}/>

            <div className="body-wrap">

                <section className="content">

                    <div className="container">

                        <div className="row ">

                            {/*BreadCrumb*/}
                            <div className="col-9">

                                {
                                    ( pathArray[0] === 'fresh-paper') &&
                                    <ul className="breadcrumb">
                                        <li><a href="/">home</a></li>
                                        <li><a href={'/fresh-paper'}>fresh paper</a></li>
                                        <li className='active'>{post.title.toLowerCase()}</li>
                                    </ul>
                                }

                                {
                                    ( pathArray[0] === 'vegan') &&
                                    <ul className="breadcrumb">
                                        <li><a href="/">home</a></li>
                                        <li><a href={'/veganchallenge'}>Vegan Challenge</a></li>
                                        <li className='active'>{post.title.toLowerCase()}</li>
                                    </ul>
                                }

                                {
                                    ( pathArray.length === 3 && pathArray[0] === 'events') &&
                                    <ul className="breadcrumb">
                                        <li><a href="/">home</a></li>
                                        <li><a href={'/events'}>events</a></li>
                                        <li className='active'>{post.title.toLowerCase()}</li>
                                    </ul>
                                }

                                {
                                    ( pathArray.length === 4 && pathArray[0] === 'events') &&
                                    <ul className="breadcrumb">
                                        <li><a href="/">home</a></li>
                                        <li><a href={'/events'}>events</a></li>
                                        {/*{(location.state) ? (location.state.prevPath) ? <li><a href={`/${pathArray[1]}/${pathArray[1]}`}>{pathArray[1]}</a></li> : <li><a href={'#'} onClick={() => navigate(-1)}>{pathArray[1]}</a></li> : <li><a href={'#'} onClick={() => navigate(-1)}>{pathArray[1]}</a></li>}*/}
                                        <li className='active'>{post.title.toLowerCase()}</li>
                                    </ul>
                                }

                            </div>

                            <div className="col-3">
                                {/*SOCIAL*/}
                                {
                                    (isDesktop || isTablet) &&
                                    <Social styling={'mobileVersion'}/>

                                }
                            </div>

                        </div>

                        <div className="row">

                            <h1>{(post.title.length > 100 ) ? parse(post.title.substring(0, 100) + "...") : parse(post.title)}</h1>

                        </div>

                        <div className="row">

                            <div className="col">

                                {featuredImage?.fluid && (
                                    <Image
                                        fluid={featuredImage.fluid}
                                        className="img-fluid full"
                                        alt={featuredImage.alt}
                                    />
                                )}

                                {/*<img src={"/image-wide.jpg"} alt="" class="img-fluid full" />*/}

                            </div>

                        </div>

                        {
                            ( post.reading.secondtitle || post.reading.smallDescription || post.reading.minuteRead) &&
                            <div className="row justify-content-center">

                                <div className="col-lg-8 col-sm-12 mt46 text-center">

                                    <h2>{post?.reading?.secondtitle}</h2>
                                    <p>{post?.reading?.smallDescription}
                                        <br/> {post?.reading?.minuteRead} &middot; { (post.reading.minuteRead) ? post.date : null }</p>

                                </div>

                            </div>
                        }

                        <div className={'row mt40'}>

                            <div className="page-content">

                                {/*{ parse(post.content) }*/}

                                {ReactHtmlParser(post.content, options)}

                            </div>

                        </div>

                    </div>

                </section>

                {/*SLIDER IMAGES*/}
                {
                    ( post?.sliderPostImages?.sliderImagesPosts ) &&
                    <section className="slider-examples">

                        <div className="container">

                            <div className="row section-title">

                                <h2>photos</h2>

                                <button onClick={myCustomPrev} class="flickity-button flickity-prev-next-button previous" type="button" aria-label="Previous"><svg class="flickity-button-icon" viewBox="0 0 100 100"><path d="M51.7,97.7l4.4-4.4c1-1,1-2.7,0-3.7L21.9,55.5H97c1.5,0,2.6-1.2,2.6-2.6v-6.2c0-1.5-1.2-2.6-2.6-2.6H21.9 L56,9.9c1-1,1-2.7,0-3.7l-4.4-4.4c-1-1-2.7-1-3.7,0L1.9,47.9c-1,1-1,2.7,0,3.7l46.1,46.1C49,98.7,50.7,98.7,51.7,97.7z" class="arrow"></path></svg></button>
                                <button onClick={myCustomNext} class="flickity-button flickity-prev-next-button next" type="button" aria-label="Next"><svg class="flickity-button-icon" viewBox="0 0 100 100"><path d="M51.7,97.7l4.4-4.4c1-1,1-2.7,0-3.7L21.9,55.5H97c1.5,0,2.6-1.2,2.6-2.6v-6.2c0-1.5-1.2-2.6-2.6-2.6H21.9 L56,9.9c1-1,1-2.7,0-3.7l-4.4-4.4c-1-1-2.7-1-3.7,0L1.9,47.9c-1,1-1,2.7,0,3.7l46.1,46.1C49,98.7,50.7,98.7,51.7,97.7z" class="arrow" transform="translate(100, 100) rotate(180) "></path></svg></button>

                            </div>

                        </div>

                        <div className="container-fluid">

                            <div className="row">

                                <Flickity flickityRef={c => (setFlkty(c))}
                                    className={'slider'} // default ''
                                    elementType={'div'} // default 'div'
                                    options={flickityOptions} // takes flickity options {}
                                    disableImagesLoaded={true} // default false
                                    reloadOnUpdate // default false
                                    static // default false
                                >

                                    { post.sliderPostImages.sliderImagesPosts.map((item, index) => {

                                        return (
                                            <div className="slide">
                                                <div className="thumbwrap">
                                                    <Image
                                                        fluid={item?.localFile?.childImageSharp?.fluid}
                                                        alt={''}
                                                        className="img-fluid full"
                                                    />
                                                </div>
                                                {/*<h3>{item?.caption}</h3>*/}
                                                {/*<p>{ (item.description) ? parse(item.description.replace(/(<([^>]+)>)/gi, "").substring(0, 40) + "...") : null}</p>*/}
                                            </div>
                                        );

                                    })}

                                </Flickity>

                            </div>

                        </div>

                    </section>
                }

                {/*EXPLORE*/}

                {
                    (getGallery.length  > 0 ) &&
                    <section className="explore">

                        <div className="container">

                            <div className="row">

                                <div className="col section-title">

                                    <h2 style={{ marginLeft: '32px'}}>fresh collection</h2>

                                </div>

                            </div>

                            <div className="row">

                                {getGallery.map((item, index) => {

                                    return (
                                        <div key={item.name} className="col-lg-3 col-sm-12">
                                            <Link to={item?.permalink} target={'_blank'} className="hover-link slide slidingGallery">
                                                <div className="thumbwrap">
                                                    <Image
                                                        fluid={item.images[0]?.localFile?.childImageSharp?.fluid}
                                                        alt={''}
                                                        className="img-fluid full"
                                                    />
                                                    {/*<img src={'/slide.jpg'} alt="" className="img-fluid full"/>*/}
                                                    <span className="more events">SHOP ONLINE</span>
                                                </div>
                                                <h3>{item.name}</h3>
                                                <p>{parse(item.short_description.substring(0, 75) + "...")}</p>
                                            </Link>
                                        </div>
                                    );

                                })}

                            </div>

                        </div>

                    </section>
                }

            </div>

            {/*<nav className="blog-post-nav">*/}
            {/*<ul*/}
            {/*style={{*/}
            {/*display: `flex`,*/}
            {/*flexWrap: `wrap`,*/}
            {/*justifyContent: `space-between`,*/}
            {/*listStyle: `none`,*/}
            {/*padding: 0,*/}
            {/*}}*/}
            {/*>*/}
            {/*<li>*/}
            {/*{previous && (*/}
            {/*<Link to={previous.uri} rel="prev">*/}
            {/*← {parse(previous.title)}*/}
            {/*</Link>*/}
            {/*)}*/}
            {/*</li>*/}

            {/*<li>*/}
            {/*{next && (*/}
            {/*<Link to={next.uri} rel="next">*/}
            {/*{parse(next.title)} →*/}
            {/*</Link>*/}
            {/*)}*/}
            {/*</li>*/}
            {/*</ul>*/}
            {/*</nav>*/}

        </Layout>
    )
}

export default NewspaperPostTemplate

export const query = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
    $language: String!
  ) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      reading{
        smallDescription
        secondtitle
        minuteRead
      }
      date(formatString: "DD.MM.YYYY")
      viewingCards {
              buttonText
              externalUrl
              showBorder
            }
      sliderPostImages {
          sliderImagesPosts {
            caption
            description
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 280, maxHeight: 420) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
      }      
      postAdSlot {
                mainLargeImagePost {
                  altText
                  localFile {
                      publicURL
                    }
                }
                smallImagePost{
                  altText
                  localFile {
                      publicURL
                    }
                }
             }
      translations{
          excerpt
          content
          title
          viewingCards {
              buttonText
              externalUrl
              showBorder
            }
          reading{
            smallDescription
            secondtitle
            minuteRead
          }
      }
    }
    newImage: wpPost(id: { eq: $id }) {
      id
      reading{
        articleMainImage {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, maxHeight: 500, quality: 80, srcSetBreakpoints: [ 400, 600, 800, 1200 ]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
      }
    }
    customWidget1: wpPost(id: { eq: $id }) {
      customPostWidget1 {
          descriptionSmallSectionWidget1
          buttonTextSmallSectionWidget1
          buttonTextLargeSectionWidget1
          showBorderLargeSectionWidget1
          showBorderSmallSectionWidget1
          urlLargeSectionWidget1
          urlSmallSectionWidget1
          titleSmallSectionWidget1
          descriptionWidget1
          titleWidget1
          imageSmallSectionWidget1 {
            localFile {
              publicURL
            }
            altText
          }
          largeImageSectionWidget1 {
            localFile {
              publicURL
            }
            altText
      }
    }
    translations {
      customPostWidget1 {
        descriptionSmallSectionWidget1
        buttonTextSmallSectionWidget1
        buttonTextLargeSectionWidget1
        showBorderLargeSectionWidget1
        showBorderSmallSectionWidget1
        urlLargeSectionWidget1
        urlSmallSectionWidget1
      }
    }
    }
    customWidget2: wpPost(id: { eq: $id }) {
      customPostWidget2 {
          descriptionSmallSectionWidget2
          buttonTextSmallSectionWidget2
          buttonTextLargeSectionWidget2
          showBorderLargeSectionWidget2
          showBorderSmallSectionWidget2
          urlLargeSectionWidget2
          urlSmallSectionWidget2
          titleSmallSectionWidget2
          descriptionWidget2
          titleWidget2
          imageSmallSectionWidget2 {
            localFile {
              publicURL
            }
            altText
          }
          largeImageSectionWidget2 {
            localFile {
              publicURL
            }
            altText
      }
    }
    translations {
      customPostWidget2 {
        descriptionSmallSectionWidget2
        buttonTextSmallSectionWidget2
        buttonTextLargeSectionWidget2
        showBorderLargeSectionWidget2
        showBorderSmallSectionWidget2
        urlLargeSectionWidget2
        urlSmallSectionWidget2
      }
    }
    }
    customWidget3: wpPost(id: { eq: $id }) {
      customPostWidget3 {
          descriptionSmallSectionWidget3
          buttonTextSmallSectionWidget3
          buttonTextLargeSectionWidget3
          showBorderLargeSectionWidget3
          showBorderSmallSectionWidget3
          urlLargeSectionWidget3
          urlSmallSectionWidget3
          titleSmallSectionWidget3
          descriptionWidget3
          titleWidget3
          imageSmallSectionWidget3 {
            localFile {
              publicURL
            }
            altText
          }
          largeImageSectionWidget3 {
            localFile {
              publicURL
            }
            altText
      }
    }
    translations {
      customPostWidget3 {
        descriptionSmallSectionWidget3
        buttonTextSmallSectionWidget3
        buttonTextLargeSectionWidget3
        showBorderLargeSectionWidget3
        showBorderSmallSectionWidget3
        urlLargeSectionWidget3
        urlSmallSectionWidget3
      }
    }
    }
    customWidget4: wpPost(id: { eq: $id }) {
      customPostWidget4 {
          descriptionSmallSectionWidget4
          buttonTextSmallSectionWidget4
          buttonTextLargeSectionWidget4
          showBorderLargeSectionWidget4
          showBorderSmallSectionWidget4
          urlLargeSectionWidget4
          urlSmallSectionWidget4
          titleSmallSectionWidget4
          descriptionWidget4
          titleWidget4
          imageSmallSectionWidget4 {
            localFile {
              publicURL
            }
            altText
          }
          largeImageSectionWidget4 {
            localFile {
              publicURL
            }
            altText
      }
    }
    translations {
      customPostWidget4 {
        descriptionSmallSectionWidget4
        buttonTextSmallSectionWidget4
        buttonTextLargeSectionWidget4
        showBorderLargeSectionWidget4
        showBorderSmallSectionWidget4
        urlLargeSectionWidget4
        urlSmallSectionWidget4
      }
    }
    }
    customWidget5: wpPost(id: { eq: $id }) {
      customPostWidget5 {
          descriptionSmallSectionWidget5
          buttonTextSmallSectionWidget5
          buttonTextLargeSectionWidget5
          showBorderLargeSectionWidget5
          showBorderSmallSectionWidget5
          urlLargeSectionWidget5
          urlSmallSectionWidget5
          titleSmallSectionWidget5
          descriptionWidget5
          titleWidget5
          imageSmallSectionWidget5 {
            localFile {
              publicURL
            }
            altText
          }
          largeImageSectionWidget5 {
            localFile {
              publicURL
            }
            altText
      }
    }
    translations {
      customPostWidget5 {
        descriptionSmallSectionWidget5
        buttonTextSmallSectionWidget5
        buttonTextLargeSectionWidget5
        showBorderLargeSectionWidget5
        showBorderSmallSectionWidget5
        urlLargeSectionWidget5
        urlSmallSectionWidget5
      }
    }
    }
    customWidget6: wpPost(id: { eq: $id }) {
      customPostWidget6 {
          descriptionSmallSectionWidget6
          buttonTextSmallSectionWidget6
          buttonTextLargeSectionWidget6
          showBorderLargeSectionWidget6
          showBorderSmallSectionWidget6
          urlLargeSectionWidget6
          urlSmallSectionWidget6
          titleSmallSectionWidget6
          descriptionWidget6
          titleWidget6
          imageSmallSectionWidget6 {
            localFile {
              publicURL
            }
            altText
          }
          largeImageSectionWidget6 {
            localFile {
              publicURL
            }
            altText
      }
    }
    translations {
      customPostWidget6 {
        descriptionSmallSectionWidget6
        buttonTextSmallSectionWidget6
        buttonTextLargeSectionWidget6
        showBorderLargeSectionWidget6
        showBorderSmallSectionWidget6
        urlLargeSectionWidget6
        urlSmallSectionWidget6
      }
    }
    }
    customWidget7: wpPost(id: { eq: $id }) {
      customPostWidget7 {
          descriptionSmallSectionWidget7
          buttonTextSmallSectionWidget7
          buttonTextLargeSectionWidget7
          showBorderLargeSectionWidget7
          showBorderSmallSectionWidget7
          urlLargeSectionWidget7
          urlSmallSectionWidget7
          titleSmallSectionWidget7
          descriptionWidget7
          titleWidget7
          imageSmallSectionWidget7 {
            localFile {
              publicURL
            }
            altText
          }
          largeImageSectionWidget7 {
            localFile {
              publicURL
            }
            altText
      }
    }
    translations {
      customPostWidget7 {
        descriptionSmallSectionWidget7
        buttonTextSmallSectionWidget7
        buttonTextLargeSectionWidget7
        showBorderLargeSectionWidget7
        showBorderSmallSectionWidget7
        urlLargeSectionWidget7
        urlSmallSectionWidget7
      }
    }
    }
    customWidget8: wpPost(id: { eq: $id }) {
      customPostWidget8 {
          descriptionSmallSectionWidget8
          buttonTextSmallSectionWidget8
          buttonTextLargeSectionWidget8
          showBorderLargeSectionWidget8
          showBorderSmallSectionWidget8
          urlLargeSectionWidget8
          urlSmallSectionWidget8
          titleSmallSectionWidget8
          descriptionWidget8
          titleWidget8
          imageSmallSectionWidget8 {
            localFile {
              publicURL
            }
            altText
          }
          largeImageSectionWidget8 {
            localFile {
              publicURL
            }
            altText
      }
    }
    translations {
      customPostWidget8 {
        descriptionSmallSectionWidget8
        buttonTextSmallSectionWidget8
        buttonTextLargeSectionWidget8
        showBorderLargeSectionWidget8
        showBorderSmallSectionWidget8
        urlLargeSectionWidget8
        urlSmallSectionWidget8
      }
    }
    }
    customWidget9: wpPost(id: { eq: $id }) {
      customPostWidget9 {
          descriptionSmallSectionWidget9
          buttonTextSmallSectionWidget9
          buttonTextLargeSectionWidget9
          showBorderLargeSectionWidget9
          showBorderSmallSectionWidget9
          urlLargeSectionWidget9
          urlSmallSectionWidget9
          titleSmallSectionWidget9
          descriptionWidget9
          titleWidget9
          imageSmallSectionWidget9 {
            localFile {
              publicURL
            }
            altText
          }
          largeImageSectionWidget9 {
            localFile {
              publicURL
            }
            altText
      }
    }
    translations {
      customPostWidget9 {
        descriptionSmallSectionWidget9
        buttonTextSmallSectionWidget9
        buttonTextLargeSectionWidget9
        showBorderLargeSectionWidget9
        showBorderSmallSectionWidget9
        urlLargeSectionWidget9
        urlSmallSectionWidget9
      }
    }
    }
    adFeatured: allWpPost(
        filter: {tags: {nodes: {elemMatch: {name: {eq: "featured post ad slot 1"}}}}}
        limit: 1
        sort: {fields: [date], order: DESC}
      ) {
        edges {
          node {
            reading {
              minuteRead
              smallDescription
              secondtitle
            }
            viewingCards {
              buttonText
              externalUrl
              showBorder
            }
            link
            title
            language {
              code
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200,maxHeight: 532, quality: 100, srcSetBreakpoints: [ 400, 600, 800, 1200 ]) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            tags {
              nodes {
                name
              }
            }
            postAdSlot {
                mainLargeImagePost {
                  altText
                  sourceUrl
                }
                smallImagePost{
                  altText
                  sourceUrl
                }
                
             }
            translations {
                    postAdSlot {
                        mainLargeImagePost {
                      altText
                      sourceUrl
                    }
                    smallImagePost{
                      altText
                      sourceUrl
                    }
                    
                 }
              reading {
                minuteRead
                smallDescription
                secondtitle
              }
              viewingCards {
                buttonText
                externalUrl
                showBorder
              }
              link
              title
              language {
                code
              }
              featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fixed(width: 1200,height: 532, quality: 100 ) {
                      ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                  }
                }
               }
              }
            }
          }
    }
  },
    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    },
    wooGallery: wcProductsCategories(wordpress_id: {eq: 520}) {
        name
        slug
        products {
          name
          short_description
          permalink
          images {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 280, maxHeight: 420) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          stock_status
        }
       }
  }
`
